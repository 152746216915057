<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <el-button type="primary" size="small" @click="Add()" plain>新增</el-button>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="adviceContent" label="退库原因"> </el-table-column>
          <el-table-column prop="addTime" label="创建日期" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.addTime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="EditBtn(scope.row)">编辑</span>
              <span class="danger optionBtn" @click="RemoveBtn(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <el-dialog :title="DialogTitle" :visible.sync="setDialog" width="30vw">
      <el-form class="dialog-form" :model="form" ref="form" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="退库原因" prop="adviceContent">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 10 }" v-model="form.adviceContent"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('form')">取 消</el-button>
        <el-button type="primary" @click="SaveBtn('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  data () {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      UserId: null,
      tableData: [],
      keyWord: "",
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      setDialog: false,
      DialogTitle: '添加',
      form: {
        "id": 0,
        "adviceContent": "",
      },
      formLabelWidth: '120px',
      rules: {
        adviceContent: [{ required: true, message: '请输入退库原因', trigger: 'blur' }],
      },
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    Add () {
      var _this = this
      _this.setDialog = true
      _this.DialogTitle = '添加'
      _this.form = {
        id: 0,
        adviceContent: '',
      }
    },
    EditBtn (e) {
      this.setDialog = true
      this.DialogTitle = '编辑'
      this.form = {
        id: e.id,
        adviceContent: e.adviceContent,
      }
    },
    SaveBtn (formName) {
      var _this = this
      _this.$refs[formName].validate(res => {
        if (res) {
          console.log(this.form);
          if (this.form.id > 0) {
            _this.OutpatientDomain.EditRefundRepertory(
              _this.form,
              function (data) {
                _this.$message({
                  type: 'success',
                  message: '修改成功!',
                })
                _this.setDialog = false
                _this.getList()
              },
              function (err) {
                console.log(err)
              }
            )
          } else {
            _this.OutpatientDomain.AddRefundRepertory(
              _this.form,
              function (data) {
                _this.$message({
                  type: 'success',
                  message: '添加成功!',
                })
                _this.setDialog = false
                _this.getList()
              },
              function (err) {
                console.log(err)
              }
            )
          }
        }
      })
    },
    RemoveBtn (e) {
      console.log(e)
      var _this = this
      _this
        .$confirm('是否删除此信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // type: 'warning'
        })
        .then(() => {
          _this.OutpatientDomain.RemoveRefundRepertory(
            e.id,
            function (data) {
              _this.$message({
                type: 'success',
                message: '删除成功!',
              })
              _this.getList()
            },
            function (err) {
              console.log(err)
            }
          )
        })
        .catch(() => { })
    },
    CloseBtn (formName) {
      this.setDialog = false
      this.$refs[formName].resetFields()
    },
    changePage (pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList () {
      var _this = this
      _this.OutpatientDomain.RefundRepertoryList(this.keyWord, this.pageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}

.table-top {
  margin-bottom: 15px;
}
</style>
